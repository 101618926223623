'use client';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Table, Tooltip, Button, Tag, type TableColumnsType } from 'antd';
import { memo, useMemo, type Key, type ReactNode } from 'react';
import styled from 'styled-components';

import useMutationSetConfigurationDeviceOverride from '~/apollo/hooks/configurationDevice/useMutationSetConfigurationDeviceOverride';
import TimeAgo from '~/components/TimeAgo';
import { DEFAULT_BASE_DEVICE_CONFIGURATION } from '~/config/defaults';
import useBrainConfigurationsContext from '~/context/useBrainConfigurationsContext';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { CONFIGURATION_SCOPE_LEVEL } from '~/types/configuration';
import type { DeviceConfiguration, DeviceConfigurationOverride } from '~/types/configurationDevice';
import getConfigurationScopeDetails from '~/utils/configurations/getConfigurationScopeDetails';
import renderTableBoolean from '~/utils/configurations/renderTableBoolean';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  activated: boolean | undefined;
  scopeType: string | undefined;
  scopeLevel: CONFIGURATION_SCOPE_LEVEL | undefined;
  companyName: string | undefined;
  subsidiaryName: string | undefined;
  agentName: string | undefined;
  wbr_orientation: DeviceConfiguration['wbr_orientation'];
  embedded_sound: DeviceConfiguration['embedded_sound'];
  delay_disconn_sec: DeviceConfiguration['delay_disconn_sec'];
  delay_bad_conn_sec: DeviceConfiguration['delay_bad_conn_sec'];
  lte_debug_mode: DeviceConfiguration['lte_debug_mode'];
  gps_pr: DeviceConfiguration['gps_pr'];
  physio_pr: DeviceConfiguration['physio_pr'];
  env_pr: DeviceConfiguration['env_pr'];
  // ...Record<`sl.${string}`, boolean>
  last_updated: string;
  actions: ReactNode;
}

interface Props {
  deviceConfigurations: (DeviceConfiguration | DeviceConfigurationOverride)[];
  isLoading: boolean;
  isBackofficePage: boolean;
}

const BrainBaseConfigurationTable = memo(
  ({ deviceConfigurations, isLoading: isLoadingFromProps, isBackofficePage }: Props) => {
    const { openModal } = useModalsContext();

    const { isLoading: isBrainConfigurationsContextLoading, refetchBrainConfigurations } =
      useBrainConfigurationsContext();

    const {
      setConfigurationDeviceOverride,
      isLoading: isMutationSetConfigurationDeviceOverrideLoading,
    } = useMutationSetConfigurationDeviceOverride();

    const isLoading =
      isLoadingFromProps ||
      isBrainConfigurationsContextLoading ||
      isMutationSetConfigurationDeviceOverrideLoading;

    const columns: TableColumnsType<TableDataType> = useMemo(
      () => [
        {
          dataIndex: 'activated',
          title: i18n.t('common.active'),
          sorter: (a, b) => (a.activated ? 1 : 0) - (b.activated ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'scopeType',
          title: i18n.t('common.scope'),
          sorter: (a, b) => (a.scopeLevel || 0) - (b.scopeLevel || 0),
          defaultSortOrder: 'ascend',
          render: (value: string) => <b>{value}</b>,
        },
        {
          dataIndex: 'companyName',
          title: i18n.t('common.company'),
          sorter: (a, b) =>
            (a.companyName || '').toLowerCase().localeCompare((b.companyName || '').toLowerCase()),
          render: (value: string) => value ?? '-',
        },
        {
          dataIndex: 'subsidiaryName',
          title: i18n.t('common.subsidiary'),
          sorter: (a, b) =>
            (a.subsidiaryName || '')
              .toLowerCase()
              .localeCompare((b.subsidiaryName || '').toLowerCase()),
          render: (value: string) => value ?? '-',
        },
        {
          dataIndex: 'agentName',
          title: i18n.t('common.agent'),
          sorter: (a, b) =>
            (a.agentName || '').toLowerCase().localeCompare((b.agentName || '').toLowerCase()),
          render: (value: string) =>
            value ? <Tag color={theme.colors.primaryBlue}>{value}</Tag> : '-',
        },
        {
          dataIndex: 'wbr_orientation',
          title: i18n.t('brainConfiguration.formValues.wbr_orientation'),
          sorter: (a, b) =>
            (a.wbr_orientation || '')
              .toLowerCase()
              .localeCompare((b.wbr_orientation || '').toLowerCase()),
          render: (value: string) =>
            value ? (
              <span
                style={{
                  minWidth: '160px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <img
                  src={curateUrl(`/images/brainOrientation/${value}.png`)}
                  alt=""
                  style={{ height: '30px' }}
                />
                {i18n.t(`brainConfiguration.orientations.${value}`)}
              </span>
            ) : (
              '-'
            ),
        },
        {
          dataIndex: 'embedded_sound',
          title: i18n.t('brainConfiguration.formValues.embedded_sound'),
          sorter: (a, b) => (a.embedded_sound ? 1 : 0) - (b.embedded_sound ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        ...(isBackofficePage
          ? [
              {
                dataIndex: 'delay_bad_conn_sec',
                title: i18n.t('brainConfiguration.formValues.delay_bad_conn_sec'),
                sorter: (a: TableDataType, b: TableDataType) =>
                  (a.delay_bad_conn_sec || 0) - (b.delay_bad_conn_sec || 0),
                render: (value: number) => value ?? '-',
              },
              {
                dataIndex: 'delay_disconn_sec',
                title: i18n.t('brainConfiguration.formValues.delay_disconn_sec'),
                sorter: (a: TableDataType, b: TableDataType) =>
                  (a.delay_disconn_sec || 0) - (b.delay_disconn_sec || 0),
                render: (value: number) => value ?? '-',
              },
              {
                dataIndex: 'lte_debug_mode',
                title: i18n.t('brainConfiguration.formValues.lte_debug_mode'),
                sorter: (a: TableDataType, b: TableDataType) =>
                  (a.lte_debug_mode ? 1 : 0) - (b.lte_debug_mode ? 1 : 0),
                render: (value: boolean) => renderTableBoolean(value),
              },
              {
                dataIndex: 'gps_pr',
                title: i18n.t('brainConfiguration.formValues.gps_pr'),
                sorter: (a: TableDataType, b: TableDataType) => (a.gps_pr || 0) - (b.gps_pr || 0),
                render: (value: number) => value ?? '-',
              },
              {
                dataIndex: 'physio_pr',
                title: i18n.t('brainConfiguration.formValues.physio_pr'),
                sorter: (a: TableDataType, b: TableDataType) =>
                  (a.physio_pr || 0) - (b.physio_pr || 0),
                render: (value: number) => value ?? '-',
              },
              {
                dataIndex: 'env_pr',
                title: i18n.t('brainConfiguration.formValues.env_pr'),
                sorter: (a: TableDataType, b: TableDataType) => (a.env_pr || 0) - (b.env_pr || 0),
                render: (value: number) => value ?? '-',
              },
              ...DEFAULT_BASE_DEVICE_CONFIGURATION.sl.map((sl) => {
                const dataIndex = `sl.${sl.name}`;
                return {
                  dataIndex,
                  title: i18n.t(`brainConfiguration.formValues.sl.${sl.name}`),
                  sorter: (a: any, b: any) => (a[dataIndex] ? 1 : 0) - (b[dataIndex] ? 1 : 0),
                  render: (value: boolean) => renderTableBoolean(value),
                };
              }),
            ]
          : []),
        {
          dataIndex: 'last_updated',
          title: i18n.t('common.updated'),
          sorter: (a, b) =>
            (a.last_updated || '')
              .toLowerCase()
              .localeCompare((b.last_updated || '').toLowerCase()),
          render: (value: string) => (
            <Tooltip title={formatDateTime(value)} placement="top">
              <span style={{ cursor: 'help' }}>
                <TimeAgo date={value} />
              </span>
            </Tooltip>
          ),
        },
        {
          dataIndex: 'actions',
          title: i18n.t('common.actions'),
          fixed: 'right',
        },
      ],
      [isBackofficePage],
    );

    const editGlobalTranslation = isBackofficePage
      ? i18n.t('configurationsGeneric.editGlobalConfiguration')
      : i18n.t('configurationsGeneric.cannotEditGlobalConfiguration');

    const data: TableDataType[] = useMemo(
      () =>
        deviceConfigurations.map((config) => {
          const scopeDetails = getConfigurationScopeDetails(
            (config as DeviceConfigurationOverride)?.scope,
          );
          const isOverride = scopeDetails.itemScope !== 'platform';
          return {
            key: JSON.stringify({ config, scopeDetails }),
            activated: config.activated ?? undefined,
            scopeType: scopeDetails.scopeName,
            scopeLevel: CONFIGURATION_SCOPE_LEVEL[scopeDetails.itemScope],
            companyName: scopeDetails.companyName,
            subsidiaryName: scopeDetails.subsidiaryName,
            agentName: scopeDetails.agentName,
            wbr_orientation: config.wbr_orientation,
            delay_disconn_sec: config.delay_disconn_sec,
            delay_bad_conn_sec: config.delay_bad_conn_sec,
            embedded_sound: config.embedded_sound,
            gps_pr: config.gps_pr,
            physio_pr: config.physio_pr,
            env_pr: config.env_pr,
            lte_debug_mode: config.lte_debug_mode,
            ...config.sl.reduce((acc, sl) => ({ ...acc, [`sl.${sl.name}`]: sl.value }), {}),
            last_updated: config.last_updated || '',
            actions: (
              <ActionsDiv>
                <Tooltip
                  title={
                    isOverride
                      ? i18n.t('configurationsGeneric.editConfigurationOverride')
                      : editGlobalTranslation
                  }
                >
                  <Button
                    disabled={(!isOverride && !isBackofficePage) || isLoading}
                    onClick={() => {
                      openModal({
                        type: 'brainConfigurationAddEdit',
                        isEdit: true,
                        itemScope: scopeDetails.itemScope,
                        itemId: scopeDetails.itemId,
                        isBackofficePage,
                      });
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    isOverride
                      ? i18n.t('configurationsGeneric.deleteConfigurationOverride')
                      : i18n.t('configurationsGeneric.cannotDeleteGlobalConfiguration')
                  }
                >
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    disabled={!isOverride || isLoading}
                    onClick={() => {
                      openModal({
                        type: 'confirmation',
                        title: i18n.t('configurationsGeneric.deleteConfigurationOverride'),
                        description: (
                          <div>
                            {i18n.t('configurationsGeneric.areYouSureYouWantToDeleteConfiguration')}
                            <br />
                            <br />
                            <b>{i18n.t('common.scope')}</b>: {scopeDetails?.scopeName}
                            {scopeDetails?.companyName && (
                              <>
                                <br />
                                <b>{i18n.t('common.company')}</b>: {scopeDetails?.companyName}
                              </>
                            )}
                            {scopeDetails?.subsidiaryName && (
                              <>
                                <br />
                                <b>{i18n.t('common.subsidiary')}</b>: {scopeDetails?.subsidiaryName}
                              </>
                            )}
                            {scopeDetails?.agentName && (
                              <>
                                <br />
                                <b>{i18n.t('common.agent')}</b>: {scopeDetails?.agentName}
                              </>
                            )}
                          </div>
                        ),
                        actionType: 'danger',
                        action: async () => {
                          try {
                            await setConfigurationDeviceOverride({
                              companyId:
                                scopeDetails.itemScope === 'company'
                                  ? scopeDetails.itemId
                                  : undefined,
                              subsidiaryId:
                                scopeDetails.itemScope === 'subsidiary'
                                  ? scopeDetails.itemId
                                  : undefined,
                              carrierId:
                                scopeDetails.itemScope === 'agent'
                                  ? scopeDetails.itemId
                                  : undefined,
                              configuration: undefined,
                            });
                            await refetchBrainConfigurations();
                            notification.success({
                              message: i18n.t('common.success'),
                              description: `${i18n.t('configurationsGeneric.configurationDeletedSuccess')} ${i18n.t('brainConfiguration.updatesWillTakeEffect')}`,
                            });
                          } catch (error) {
                            notification.error({
                              message: (error as any)?.message || i18n.t('common.error'),
                            });
                          }
                        },
                      });
                    }}
                  />
                </Tooltip>
              </ActionsDiv>
            ),
          };
        }),
      [
        deviceConfigurations,
        editGlobalTranslation,
        isBackofficePage,
        isLoading,
        openModal,
        setConfigurationDeviceOverride,
        refetchBrainConfigurations,
      ],
    );

    return (
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} configurations`,
          hideOnSinglePage: true,
        }}
      />
    );
  },
);

BrainBaseConfigurationTable.displayName = 'BrainBaseConfigurationTable';

export default BrainBaseConfigurationTable;
