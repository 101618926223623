'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import QUERY_COMPANY_LIST, { type CompanyListQueryData } from './queries/QueryCompanyList';

export default function useQueryCompanyList(skip = false): {
  companyList: CompanyListQueryData['getAllCompanies']['items'];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<CompanyListQueryData>(QUERY_COMPANY_LIST, {
    skip,
  });

  return useMemo(
    () => ({
      companyList: orderBy(
        data?.getAllCompanies?.items || [],
        [(company) => company.name.toLowerCase()],
        ['asc'],
      ),
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.getAllCompanies?.items, loading, error, refetch],
  );
}
