'use client';

import { memo } from 'react';

interface Props {
  color: string;
  size: number;
}

const AlarmBottomBannerIcon = memo(({ color, size }: Props) => (
  <svg
    fill={color}
    height={`${size}px`}
    width={`${size}px`}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 298.25 298.25"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M232.125,149c0-46.116-37.384-83.5-83.5-83.5s-83.5,37.384-83.5,83.5v83.25h167V149z M148.208,118.25
		c-16.956,0-30.75,13.794-30.75,30.75c0,4.143-3.357,7.5-7.5,7.5s-7.5-3.357-7.5-7.5c0-25.227,20.523-45.75,45.75-45.75
		c4.143,0,7.5,3.357,7.5,7.5S152.351,118.25,148.208,118.25z"
      />
      <path
        d="M249.156,247.25H49.842c-13.496,0-24.717,15.596-24.717,29.097v21.903h248v-21.903
		C273.125,262.846,262.65,247.25,249.156,247.25z"
      />
      <path
        d="M148.625,50c4.143,0,7.5-3.357,7.5-7.5v-35c0-4.143-3.357-7.5-7.5-7.5s-7.5,3.357-7.5,7.5v35
		C141.125,46.643,144.482,50,148.625,50z"
      />
      <path
        d="M89.819,60.445c1.39,2.406,3.91,3.751,6.502,3.751c1.272,0,2.563-0.324,3.743-1.006c3.587-2.071,4.816-6.658,2.745-10.245
		l-17.5-30.311c-2.071-3.587-6.66-4.816-10.245-2.745c-3.587,2.071-4.816,6.658-2.745,10.245L89.819,60.445z"
      />
      <path
        d="M23.761,84.306l30.311,17.5c1.182,0.682,2.471,1.006,3.743,1.006c2.592,0,5.113-1.345,6.502-3.751
		c2.071-3.587,0.842-8.174-2.745-10.245l-30.311-17.5c-3.59-2.07-8.175-0.843-10.245,2.745
		C18.944,77.647,20.174,82.234,23.761,84.306z"
      />
      <path
        d="M198.186,63.19c1.182,0.682,2.471,1.006,3.743,1.006c2.592,0,5.113-1.345,6.502-3.751l17.5-30.311
		c2.071-3.587,0.842-8.174-2.745-10.245c-3.589-2.071-8.176-0.843-10.245,2.745l-17.5,30.311
		C193.369,56.532,194.599,61.119,198.186,63.19z"
      />
      <path
        d="M240.436,102.812c1.272,0,2.563-0.324,3.743-1.006l30.311-17.5c3.587-2.071,4.816-6.658,2.745-10.245
		c-2.072-3.588-6.66-4.815-10.245-2.745l-30.311,17.5c-3.587,2.071-4.816,6.658-2.745,10.245
		C235.323,101.467,237.844,102.812,240.436,102.812z"
      />
    </g>
  </svg>
));

AlarmBottomBannerIcon.displayName = 'AlarmBottomBannerIcon';

export default AlarmBottomBannerIcon;
