'use client';

import isBoolean from 'lodash/isBoolean';
import type { ReactNode } from 'react';

import theme from '~/theme';
import getYesOrNo from '~/utils/parse/getYesOrNo';

export default function renderTableBoolean(value: boolean): ReactNode {
  return isBoolean(value) ? (
    <span style={{ color: value ? theme.colors.green : theme.colors.red }}>
      {getYesOrNo(value)}
    </span>
  ) : (
    '-'
  );
}
