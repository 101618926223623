'use client';

import i18n from '~/locales/i18n';
import type { ConfigurationOverrideScope, ConfigurationClientScope } from '~/types/configuration';

export default function getConfigurationScopeDetails(scope: ConfigurationOverrideScope): {
  itemScope: ConfigurationClientScope;
  itemId: string | undefined;
  scopeName: string;
  companyName: string | undefined;
  subsidiaryName: string | undefined;
  agentName: string | undefined;
} {
  switch (scope?.__typename) {
    case 'Company':
      return {
        itemScope: 'company',
        itemId: scope?.id,
        scopeName: i18n.t('common.company'),
        companyName: scope?.name,
        subsidiaryName: undefined,
        agentName: undefined,
      };
    case 'Subsidiary':
      return {
        itemScope: 'subsidiary',
        itemId: scope?.id,
        scopeName: i18n.t('common.subsidiary'),
        companyName: scope?.company?.name,
        subsidiaryName: scope?.name,
        agentName: undefined,
      };
    case 'Carrier_Cognito':
    case 'Carrier_NonCognito':
      return {
        itemScope: 'agent',
        itemId: scope?.id,
        scopeName: i18n.t('common.agent'),
        companyName: scope?.subsidiary?.company.name,
        subsidiaryName: scope?.subsidiary?.name,
        agentName: scope?.name,
      };
    default:
      return {
        itemScope: 'platform',
        itemId: scope?.id,
        scopeName: i18n.t('common.platform'),
        companyName: undefined,
        subsidiaryName: undefined,
        agentName: undefined,
      };
  }
}
