'use client';

import { useMemo } from 'react';

import useAlarmsContext from '~/context/useAlarmsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import { ALARM_TYPE } from '~/types/alarm';
import { EQUIPMENT_STATUS } from '~/types/equipment';
import getEquipmentStatusLabel from '~/utils/equipment/getEquipmentStatusLabel';

import ContentGasSensors from '../components/ContentGasSensors';
import ContentHeartRate from '../components/ContentHeartRate';
import ContentImpactDetection from '../components/ContentImpactDetection';
import ContentOxygenSupply from '../components/ContentOxygenSupply';
import ContentPhysiologicalTemperature from '../components/ContentPhysiologicalTemperature';
import ContentRadiation from '../components/ContentRadiation';
import type { EquipmentItem } from '../components/EquipmentItemCard';

export default function useAgentEquipment(agent: Agent | undefined): {
  equipmentItems: EquipmentItem[];
  hasImpactDetectionAlarm: boolean;
} {
  const { companyFeatures } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const hasImpactDetectionAlarm = useMemo(
    () =>
      (agentOngoingAlarms || []).some((alarm) =>
        [ALARM_TYPE.traak_front, ALARM_TYPE.traak_back].includes(alarm.type),
      ),
    [agentOngoingAlarms],
  );

  const hasGasAlarm = useMemo(
    () =>
      (agentOngoingAlarms || []).some((alarm) =>
        [ALARM_TYPE.gas_danger, ALARM_TYPE.gas_high].includes(alarm.type),
      ),
    [agentOngoingAlarms],
  );

  const equipmentItems: EquipmentItem[] = useMemo(
    () =>
      [
        ...(companyFeatures.emergencyButton
          ? [
              {
                key: 'emergencyButton',
                label: i18n.t('carrierDetailsPopup.equipment.sosExternalButton'),
                statusDetails: agent?.equipmentStatus?.emergencyButton,
                errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.emergencyButton),
                content: null,
                isOpen: false,
              },
            ]
          : []),
        ...(companyFeatures.heartRateSensor
          ? [
              {
                key: 'heartRateSensor',
                label: i18n.t('carrierDetailsPopup.equipment.heartRate'),
                statusDetails: agent?.equipmentStatus?.heartRate,
                errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.heartRate),
                content: companyFeatures.biometricData ? (
                  <ContentHeartRate
                    agent={agent}
                    isHealthy={!!agent?.equipmentStatus?.heartRate?.healthy}
                  />
                ) : null,
                isOpen: false,
              },
            ]
          : []),
        ...(companyFeatures.physiologicalTemperatureSensor || companyFeatures.bodyTemperatureSensor
          ? [
              {
                key: 'physiologicalTemperatureSensor',
                label: i18n.t('carrierDetailsPopup.equipment.physiologicalTemperature'),
                statusDetails: companyFeatures.physiologicalTemperatureSensor
                  ? agent?.equipmentStatus?.physiologicalTemperature
                  : agent?.equipmentStatus?.bodyTemperature,
                errorTooltip: companyFeatures.physiologicalTemperatureSensor
                  ? getEquipmentStatusLabel(agent?.equipmentStatus?.physiologicalTemperature)
                  : getEquipmentStatusLabel(agent?.equipmentStatus?.bodyTemperature),
                content: companyFeatures.biometricData ? (
                  <ContentPhysiologicalTemperature
                    agent={agent}
                    isHealthy={
                      companyFeatures.physiologicalTemperatureSensor
                        ? !!agent?.equipmentStatus?.physiologicalTemperature?.healthy
                        : !!agent?.equipmentStatus?.bodyTemperature?.healthy
                    }
                  />
                ) : null,
                isOpen: false,
              },
            ]
          : []),
        ...(companyFeatures.gasSensor
          ? [
              {
                key: 'gasSensors',
                label: i18n.t('carrierDetailsPopup.equipment.gasSensors'),
                statusDetails: agent?.equipmentStatus?.gas,
                errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.gas),
                content: (
                  <ContentGasSensors
                    agent={agent}
                    isHealthy={!!agent?.equipmentStatus?.gas?.healthy}
                  />
                ),
                isOpen: hasGasAlarm,
              },
            ]
          : []),
        ...(companyFeatures.radiationSensor
          ? [
              {
                key: 'radiationSensors',
                label: i18n.t('carrierDetailsPopup.equipment.radiation'),
                statusDetails: agent?.equipmentStatus?.radiation,
                errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.radiation),
                content: (
                  <ContentRadiation
                    agent={agent}
                    isHealthy={!!agent?.equipmentStatus?.radiation?.healthy}
                  />
                ),
                isOpen: false,
              },
            ]
          : []),
        ...(companyFeatures.oxygenSupplySensor
          ? [
              {
                key: 'oxygenSupplySensor',
                label: i18n.t('carrierDetailsPopup.equipment.oxygenSupply'),
                statusDetails: agent?.equipmentStatus?.oxygenSupply,
                errorTooltip: getEquipmentStatusLabel(agent?.equipmentStatus?.oxygenSupply),
                content: (
                  <ContentOxygenSupply
                    agent={agent}
                    isHealthy={!!agent?.equipmentStatus?.oxygenSupply?.healthy}
                  />
                ),
                isOpen: false,
              },
            ]
          : []),
        ...(companyFeatures.impactDetectionFront || companyFeatures.impactDetectionBack
          ? [
              {
                key: 'impactDetection',
                label: i18n.t('carrierDetailsPopup.equipment.impactDetection'),
                statusDetails:
                  (companyFeatures.impactDetectionFront
                    ? agent?.equipmentStatus?.traakFront
                    : undefined) &&
                  (companyFeatures.impactDetectionBack
                    ? agent?.equipmentStatus?.traakBack
                    : undefined),
                errorTooltip:
                  (companyFeatures.impactDetectionFront
                    ? getEquipmentStatusLabel(agent?.equipmentStatus?.traakFront)
                    : undefined) ||
                  (companyFeatures.impactDetectionBack
                    ? getEquipmentStatusLabel(agent?.equipmentStatus?.traakBack)
                    : undefined),
                content: <ContentImpactDetection agent={agent} />,
                isOpen: hasImpactDetectionAlarm,
              },
            ]
          : []),
      ].map((item) => ({
        // Don't show equipment items if the agent is offline
        ...item,
        statusDetails: agent?.isOffline
          ? {
              status: EQUIPMENT_STATUS.no_sensor,
              healthy: false,
            }
          : item.statusDetails,
        errorTooltip: agent?.isOffline ? '' : item.errorTooltip,
      })),
    [
      agent,
      companyFeatures.biometricData,
      companyFeatures.bodyTemperatureSensor,
      companyFeatures.emergencyButton,
      companyFeatures.gasSensor,
      companyFeatures.radiationSensor,
      companyFeatures.heartRateSensor,
      companyFeatures.impactDetectionBack,
      companyFeatures.impactDetectionFront,
      companyFeatures.physiologicalTemperatureSensor,
      companyFeatures.oxygenSupplySensor,
      hasGasAlarm,
      hasImpactDetectionAlarm,
    ],
  );

  return useMemo(
    () => ({
      equipmentItems: agent ? equipmentItems : [],
      hasImpactDetectionAlarm,
    }),
    [agent, equipmentItems, hasImpactDetectionAlarm],
  );
}
