'use client';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Table, Tooltip, Button, Tag, Typography, type TableColumnsType } from 'antd';
import { memo, useMemo, type Key, type ReactNode } from 'react';
import styled from 'styled-components';

import useMutationSetConfigurationDeviceOverride from '~/apollo/hooks/configurationDevice/useMutationSetConfigurationDeviceOverride';
import TimeAgo from '~/components/TimeAgo';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { ALARM_TYPE } from '~/types/alarm';
import { CONFIGURATION_SCOPE_LEVEL } from '~/types/configuration';
import { AlarmConfiguration, AlarmConfigurationOverride } from '~/types/configurationAlarm';
import getConfigurationScopeDetails from '~/utils/configurations/getConfigurationScopeDetails';
import renderTableBoolean from '~/utils/configurations/renderTableBoolean';
import { formatDateTime } from '~/utils/dateTime';
import notification from '~/utils/notification';

const StyledTable = styled(Table)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionsDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

interface TableDataType {
  key: Key;
  activated: boolean | undefined;
  scopeType: string | undefined;
  scopeLevel: CONFIGURATION_SCOPE_LEVEL | undefined;
  companyName: string | undefined;
  subsidiaryName: string | undefined;
  agentName: string | undefined;
  selectedAlarmType: ALARM_TYPE | undefined;
  level: AlarmConfiguration['level'];
  authority: AlarmConfiguration['authority'];
  notify_sms: AlarmConfiguration['notify_sms'];
  notify_voice_call: AlarmConfiguration['notify_voice_call'];
  activate_video: AlarmConfiguration['activate_video'];
  vest_feedback: AlarmConfiguration['vest_feedback'];
  can_assign: AlarmConfiguration['can_assign'];
  can_view_other_carriers: AlarmConfiguration['can_view_other_carriers'];
  can_view_teamleader: AlarmConfiguration['can_view_teamleader'];
  can_dismiss_dashboard: AlarmConfiguration['can_dismiss_dashboard'];
  can_dismiss_teamleader: AlarmConfiguration['can_dismiss_teamleader'];
  can_dismiss_carrier: AlarmConfiguration['can_dismiss_carrier'];
  can_cancel: AlarmConfiguration['can_cancel'];
  trigger_parameters: AlarmConfiguration['trigger_parameters'];
  last_updated: string;
  actions: ReactNode;
}

interface Props {
  selectedAlarmType: ALARM_TYPE;
  alarmConfigurations: (AlarmConfiguration | AlarmConfigurationOverride)[];
  isLoading: boolean;
  refetchAlarmConfigurations: () => void;
}

const AlarmConfigurationsTable = memo(
  ({
    selectedAlarmType,
    alarmConfigurations,
    isLoading: isLoadingFromProps,
    refetchAlarmConfigurations,
  }: Props) => {
    const { openModal } = useModalsContext();

    const {
      setConfigurationDeviceOverride,
      isLoading: isMutationSetConfigurationDeviceOverrideLoading,
    } = useMutationSetConfigurationDeviceOverride();

    const isLoading = isLoadingFromProps || isMutationSetConfigurationDeviceOverrideLoading;

    const columns: TableColumnsType<TableDataType> = useMemo(
      () => [
        {
          dataIndex: 'activated',
          title: i18n.t('common.active'),
          sorter: (a, b) => (a.activated ? 1 : 0) - (b.activated ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'selectedAlarmType',
          title: i18n.t('common.type'),
          render: (value: ALARM_TYPE) => <b>{i18n.t(`general.alarm.type.${value}`)}</b>,
          sorter: (a, b) =>
            (a.selectedAlarmType || '').localeCompare((b.selectedAlarmType || '').toLowerCase()),
        },
        {
          dataIndex: 'scopeType',
          title: i18n.t('common.scope'),
          sorter: (a, b) => (a.scopeLevel || 0) - (b.scopeLevel || 0),
          defaultSortOrder: 'ascend',
          render: (value: string) => <b>{value}</b>,
        },
        {
          dataIndex: 'companyName',
          title: i18n.t('common.company'),
          sorter: (a, b) =>
            (a.companyName || '').toLowerCase().localeCompare((b.companyName || '').toLowerCase()),
          render: (value: string) => value ?? '-',
        },
        {
          dataIndex: 'subsidiaryName',
          title: i18n.t('common.subsidiary'),
          sorter: (a, b) =>
            (a.subsidiaryName || '')
              .toLowerCase()
              .localeCompare((b.subsidiaryName || '').toLowerCase()),
          render: (value: string) => value ?? '-',
        },
        {
          dataIndex: 'agentName',
          title: i18n.t('common.agent'),
          sorter: (a, b) =>
            (a.agentName || '').toLowerCase().localeCompare((b.agentName || '').toLowerCase()),
          render: (value: string) =>
            value ? <Tag color={theme.colors.primaryBlue}>{value}</Tag> : '-',
        },
        {
          dataIndex: 'level',
          title: i18n.t('alarmConfigurations.level'),
          sorter: (a, b) =>
            (a.level || '').toLowerCase().localeCompare((b.level || '').toLowerCase()),
          render: (value: string) => value ?? '-',
        },
        {
          dataIndex: 'authority',
          title: i18n.t('alarmConfigurations.authority'),
          sorter: (a, b) =>
            (a.authority || '').toLowerCase().localeCompare((b.authority || '').toLowerCase()),
          render: (value: string) => value ?? '-',
        },
        {
          dataIndex: 'notify_sms',
          title: i18n.t('alarmConfigurations.notifySms'),
          sorter: (a, b) => (a.notify_sms ? 1 : 0) - (b.notify_sms ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'notify_voice_call',
          title: i18n.t('alarmConfigurations.notifyVoiceCall'),
          sorter: (a, b) => (a.notify_voice_call ? 1 : 0) - (b.notify_voice_call ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'activate_video',
          title: i18n.t('alarmConfigurations.activateVideo'),
          sorter: (a, b) => (a.activate_video ? 1 : 0) - (b.activate_video ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'vest_feedback',
          title: i18n.t('alarmConfigurations.vestFeedback'),
          sorter: (a, b) => (a.vest_feedback ? 1 : 0) - (b.vest_feedback ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_assign',
          title: i18n.t('alarmConfigurations.canAssign'),
          sorter: (a, b) => (a.can_assign ? 1 : 0) - (b.can_assign ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_view_other_carriers',
          title: i18n.t('alarmConfigurations.canViewOtherCarriers'),
          sorter: (a, b) =>
            (a.can_view_other_carriers ? 1 : 0) - (b.can_view_other_carriers ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_view_teamleader',
          title: i18n.t('alarmConfigurations.canViewTeamLeader'),
          sorter: (a, b) => (a.can_view_teamleader ? 1 : 0) - (b.can_view_teamleader ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_dismiss_dashboard',
          title: i18n.t('alarmConfigurations.canDismissDashboard'),
          sorter: (a, b) => (a.can_dismiss_dashboard ? 1 : 0) - (b.can_dismiss_dashboard ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_dismiss_teamleader',
          title: i18n.t('alarmConfigurations.canDismissTeamLeader'),
          sorter: (a, b) => (a.can_dismiss_teamleader ? 1 : 0) - (b.can_dismiss_teamleader ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_dismiss_carrier',
          title: i18n.t('alarmConfigurations.canDismissCarrier'),
          sorter: (a, b) => (a.can_dismiss_carrier ? 1 : 0) - (b.can_dismiss_carrier ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'can_cancel',
          title: i18n.t('alarmConfigurations.canCancel'),
          sorter: (a, b) => (a.can_cancel ? 1 : 0) - (b.can_cancel ? 1 : 0),
          render: (value: boolean) => renderTableBoolean(value),
        },
        {
          dataIndex: 'trigger_parameters',
          title: i18n.t('alarmConfigurations.triggerParameters'),
          sorter: (a, b) => (a.trigger_parameters || '').localeCompare(b.trigger_parameters || ''),
          render: (value: string) =>
            value ? (
              <Typography.Text code ellipsis>
                {value}
              </Typography.Text>
            ) : (
              '-'
            ),
        },
        {
          dataIndex: 'last_updated',
          title: i18n.t('common.updated'),
          sorter: (a, b) =>
            (a.last_updated || '')
              .toLowerCase()
              .localeCompare((b.last_updated || '').toLowerCase()),
          render: (value: string) => (
            <Tooltip title={formatDateTime(value)} placement="top">
              <span style={{ cursor: 'help' }}>
                <TimeAgo date={value} />
              </span>
            </Tooltip>
          ),
        },
        {
          dataIndex: 'actions',
          title: i18n.t('common.actions'),
          fixed: 'right',
        },
      ],
      [],
    );

    const data: TableDataType[] = useMemo(
      () =>
        alarmConfigurations.map((config) => {
          const scopeDetails = getConfigurationScopeDetails(
            (config as AlarmConfigurationOverride)?.scope,
          );
          const isOverride = scopeDetails.itemScope !== 'platform';
          return {
            key: JSON.stringify({ config, scopeDetails }),
            activated: config.activated ?? undefined,
            scopeType: scopeDetails.scopeName,
            scopeLevel: CONFIGURATION_SCOPE_LEVEL[scopeDetails.itemScope],
            companyName: scopeDetails.companyName,
            subsidiaryName: scopeDetails.subsidiaryName,
            agentName: scopeDetails.agentName,
            selectedAlarmType,
            level: config.level,
            authority: config.authority,
            notify_sms: config.notify_sms,
            notify_voice_call: config.notify_voice_call,
            activate_video: config.activate_video,
            vest_feedback: config.vest_feedback,
            can_assign: config.can_assign,
            can_view_other_carriers: config.can_view_other_carriers,
            can_view_teamleader: config.can_view_teamleader,
            can_dismiss_dashboard: config.can_dismiss_dashboard,
            can_dismiss_teamleader: config.can_dismiss_teamleader,
            can_dismiss_carrier: config.can_dismiss_carrier,
            can_cancel: config.can_cancel,
            trigger_parameters: config.trigger_parameters,
            last_updated: config.last_updated || '',
            actions: (
              <ActionsDiv>
                <Tooltip
                  title={
                    isOverride
                      ? i18n.t('configurationsGeneric.editConfigurationOverride')
                      : i18n.t('configurationsGeneric.editGlobalConfiguration')
                  }
                >
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      openModal({
                        type: 'alarmConfigurationAddEdit',
                        isEdit: true,
                        itemScope: scopeDetails.itemScope,
                        itemId: scopeDetails.itemId,
                        selectedAlarmType,
                        alarmConfigurations,
                        refetchAlarmConfigurations,
                      });
                    }}
                    icon={<EditOutlined />}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    isOverride
                      ? i18n.t('configurationsGeneric.deleteConfigurationOverride')
                      : i18n.t('configurationsGeneric.cannotDeleteGlobalConfiguration')
                  }
                >
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    disabled={!isOverride || isLoading}
                    onClick={() => {
                      openModal({
                        type: 'confirmation',
                        title: i18n.t('configurationsGeneric.deleteConfigurationOverride'),
                        description: (
                          <div>
                            {i18n.t('configurationsGeneric.areYouSureYouWantToDeleteConfiguration')}
                            <br />
                            <br />
                            <b>{i18n.t('common.alarmType')}</b>:{' '}
                            {i18n.t(`general.alarm.type.${selectedAlarmType}`)}
                            <br />
                            <b>{i18n.t('common.scope')}</b>: {scopeDetails?.scopeName}
                            {scopeDetails?.companyName && (
                              <>
                                <br />
                                <b>{i18n.t('common.company')}</b>: {scopeDetails?.companyName}
                              </>
                            )}
                            {scopeDetails?.subsidiaryName && (
                              <>
                                <br />
                                <b>{i18n.t('common.subsidiary')}</b>: {scopeDetails?.subsidiaryName}
                              </>
                            )}
                            {scopeDetails?.agentName && (
                              <>
                                <br />
                                <b>{i18n.t('common.agent')}</b>: {scopeDetails?.agentName}
                              </>
                            )}
                          </div>
                        ),
                        actionType: 'danger',
                        action: async () => {
                          try {
                            await setConfigurationDeviceOverride({
                              companyId:
                                scopeDetails.itemScope === 'company'
                                  ? scopeDetails.itemId
                                  : undefined,
                              subsidiaryId:
                                scopeDetails.itemScope === 'subsidiary'
                                  ? scopeDetails.itemId
                                  : undefined,
                              carrierId:
                                scopeDetails.itemScope === 'agent'
                                  ? scopeDetails.itemId
                                  : undefined,
                              configuration: undefined,
                            });
                            await refetchAlarmConfigurations();
                            notification.success({
                              message: i18n.t('common.success'),
                              description: i18n.t(
                                'configurationsGeneric.configurationDeletedSuccess',
                              ),
                            });
                          } catch (error) {
                            notification.error({
                              message: (error as any)?.message || i18n.t('common.error'),
                            });
                          }
                        },
                      });
                    }}
                  />
                </Tooltip>
              </ActionsDiv>
            ),
          };
        }),
      [
        selectedAlarmType,
        alarmConfigurations,
        isLoading,
        refetchAlarmConfigurations,
        openModal,
        setConfigurationDeviceOverride,
      ],
    );

    return (
      <StyledTable
        tableLayout="auto"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        sortDirections={['ascend', 'descend', 'ascend']}
        pagination={{
          defaultPageSize: 1000,
          showSizeChanger: false,
          pageSizeOptions: undefined,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} configurations`,
          hideOnSinglePage: true,
        }}
      />
    );
  },
);

export default AlarmConfigurationsTable;
