'use client';

import { useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import type { DeviceConfigurationOverride } from '~/types/configurationDevice';

import QUERY_DEVICE_CONFIGURATION_OVERRIDES_FOR_AGENTS, {
  type DeviceConfigurationOverridesForAgentsQueryData,
  type DeviceConfigurationOverridesForAgentsQueryVariables,
} from './queries/QueryDeviceConfigurationOverridesForAgents';

export default function useQueryDeviceConfigurationOverridesForAgents({
  subsidiaryId,
  skip,
}: {
  subsidiaryId: string | undefined;
  skip: boolean;
}): {
  deviceConfigurationOverridesForAgents: DeviceConfigurationOverride[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => void;
} {
  const { data, loading, error, refetch } = useQuery<
    DeviceConfigurationOverridesForAgentsQueryData,
    DeviceConfigurationOverridesForAgentsQueryVariables
  >(QUERY_DEVICE_CONFIGURATION_OVERRIDES_FOR_AGENTS, {
    variables: {
      subsidiaryId: subsidiaryId || '',
      nextToken: null,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: skip || !subsidiaryId,
  });

  return useMemo(
    () => ({
      deviceConfigurationOverridesForAgents: (data?.subsidiary?.carriers?.items || []).map(
        (item) => item.configuration,
      ),
      isLoading: loading,
      error,
      refetch,
    }),
    [data?.subsidiary?.carriers?.items, loading, error, refetch],
  );
}
