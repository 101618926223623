'use client';

import { Tooltip } from 'antd';
import isBoolean from 'lodash/isBoolean';
import { memo } from 'react';

import GenericItemList, { DEFAULT_VALUE, type GenericItem } from '~/components/GenericItemList';
import TimeAgo from '~/components/TimeAgo';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { AlarmConfiguration } from '~/types/configurationAlarm';
import { formatDateTime } from '~/utils/dateTime';
import getYesOrNo from '~/utils/parse/getYesOrNo';

export interface Props {
  alarmConfiguration: AlarmConfiguration;
}

const AlarmConfigurationItemList = memo(({ alarmConfiguration }: Props) => {
  const items: GenericItem[] = [
    {
      label: i18n.t('alarmConfigurations.lastUpdate'),
      value: (
        <Tooltip placement="right" title={formatDateTime(alarmConfiguration.last_updated || '')}>
          <span style={{ cursor: 'help' }}>
            <TimeAgo date={alarmConfiguration.last_updated || ''} />
          </span>
        </Tooltip>
      ),
    },
    {
      label: i18n.t('alarmConfigurations.activated'),
      value: isBoolean(alarmConfiguration.activated) ? (
        <span
          style={{
            fontWeight: 'bold',
            color: alarmConfiguration.activated ? theme.colors.green : theme.colors.red,
          }}
        >
          {getYesOrNo(alarmConfiguration.activated, DEFAULT_VALUE)}
        </span>
      ) : (
        getYesOrNo(alarmConfiguration.activated, DEFAULT_VALUE)
      ),
    },
    {
      label: i18n.t('alarmConfigurations.authority'),
      value: alarmConfiguration.authority
        ? i18n.t(
            `general.alarmConfiguration.authority.${alarmConfiguration.authority.toLowerCase()}`,
          )
        : DEFAULT_VALUE,
    },
    {
      label: i18n.t('alarmConfigurations.level'),
      value: alarmConfiguration.level
        ? i18n.t(`general.alarm.level.${alarmConfiguration.level.toLowerCase()}`)
        : DEFAULT_VALUE,
    },
    {
      label: i18n.t('alarmConfigurations.canAssign'),
      value: getYesOrNo(alarmConfiguration.can_assign, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.canCancel'),
      value: getYesOrNo(alarmConfiguration.can_cancel, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.canViewTeamLeader'),
      value: getYesOrNo(alarmConfiguration.can_view_teamleader, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.canViewOtherCarriers'),
      value: getYesOrNo(alarmConfiguration.can_view_other_carriers, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.canDismissDashboard'),
      value: getYesOrNo(alarmConfiguration.can_dismiss_dashboard, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.canDismissTeamLeader'),
      value: getYesOrNo(alarmConfiguration.can_dismiss_teamleader, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.canDismissCarrier'),
      value: getYesOrNo(alarmConfiguration.can_dismiss_carrier, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.activateVideo'),
      value: getYesOrNo(alarmConfiguration.activate_video, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.notifySms'),
      value: getYesOrNo(alarmConfiguration.notify_sms, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.notifyVoiceCall'),
      value: getYesOrNo(alarmConfiguration.notify_voice_call, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.vestFeedback'),
      value: getYesOrNo(alarmConfiguration.vest_feedback, DEFAULT_VALUE),
    },
    {
      label: i18n.t('alarmConfigurations.triggerParameters'),
      value: alarmConfiguration.trigger_parameters || DEFAULT_VALUE,
    },
  ];

  return <GenericItemList items={items} />;
});

AlarmConfigurationItemList.displayName = 'AlarmConfigurationItemList';

export default AlarmConfigurationItemList;
