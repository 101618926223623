'use client';

import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { AutoComplete, Button, Form, Input, Radio, Space, Tooltip } from 'antd';
import uniq from 'lodash/uniq';
import { memo, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import useMutationCreateAgentCognito from '~/apollo/hooks/agent/useMutationCreateAgentCognito';
import useMutationCreateAgentNonCognito from '~/apollo/hooks/agent/useMutationCreateAgentNonCognito';
import useMutationSetAgentAttributes from '~/apollo/hooks/agent/useMutationSetAgentAttributes';
import PhoneNumberInput from '~/components/PhoneNumberInput';
import SettingsSelectSubsidiary from '~/components/settings/components/SettingsSelectSubsidiary';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import notification from '~/utils/notification';
import isValidEmail from '~/utils/validation/isValidEmail';

import AccountCreated from './components/AccountCreated';

const WillAgentUseAppTooltipDiv = styled.div`
  & ul {
    padding-left: 16px;
  }
`;

const BottomFlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BottomDiv = styled.div`
  margin-top: 16px;
  opacity: 0.5;
`;

const formAgentCodeValidator = (rule: object, value: string) =>
  value?.trim().length > 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidAgentCode')));

const formNameValidator = (rule: object, value: string) =>
  !value || value.trim().length >= 1
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidName')));

const formEmailValidator = (rule: object, value: string) =>
  isValidEmail(value)
    ? Promise.resolve()
    : Promise.reject(Error(i18n.t<string>('agentForm.invalidEmail')));

interface FormValues {
  id: string;
  willAgentUseApp: 'YES' | 'NO';
  name: string;
  email: string;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneNumber: string | undefined;
  team: string | undefined;
}

interface Props {
  agent: Agent | undefined;
  onSuccess: () => Promise<void>;
  onClose: () => void;
}

const AgentAddEditForm = memo(({ agent, onSuccess, onClose }: Props) => {
  const { isSuperAdmin, isCompanyAdmin } = useCurrentUserContext();

  const isCompanyAdminOrSuperAdmin = isCompanyAdmin || isSuperAdmin;

  const initialValues = useMemo(() => {
    const formValues: FormValues = {
      id: agent?.id || '',
      willAgentUseApp: agent?.email ? 'YES' : 'NO',
      name: agent?.name || '',
      email: agent?.email || '',
      firstName: agent?.attributes?.first_name || undefined,
      lastName: agent?.attributes?.last_name || undefined,
      phoneNumber: agent?.attributes?.phone_number || undefined,
      team: agent?.attributes?.team || undefined,
    };

    return agent ? formValues : undefined;
  }, [agent]);

  const { agents } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const {
    createAgentCognito,
    isLoading: isCreateCognitoLoading,
    createdAgentCognito,
  } = useMutationCreateAgentCognito();
  const { createAgentNonCognito, isLoading: isCreateNonCognitoLoading } =
    useMutationCreateAgentNonCognito();
  const { setAgentAttributes, loading: isEditLoading } = useMutationSetAgentAttributes();
  const [form] = Form.useForm<FormValues>();

  const willAgentUseApp = Form.useWatch('willAgentUseApp', form);
  const phoneNumber = Form.useWatch('phoneNumber', form);

  const allTeams = useMemo(
    () => uniq(agents?.map(({ team }) => team)?.filter((team) => team)),
    [agents],
  );

  const isLoading = isCreateCognitoLoading || isCreateNonCognitoLoading || isEditLoading;
  const actionType = initialValues ? 'edit' : 'add';
  const isEdit = actionType === 'edit';

  const [autoCompleteText, setAutoCompleteText] = useState<string>('');

  const wasAgentCreated = !!createdAgentCognito?.tempPassword;

  const teamOptions = useMemo(
    () =>
      allTeams
        .filter((teamName) => teamName.toLowerCase().includes(autoCompleteText))
        .map((teamName) => ({ label: teamName, value: teamName })),
    [allTeams, autoCompleteText],
  );

  const handleTeamInputChange = useCallback((value: string) => {
    setAutoCompleteText(value?.trim()?.toLowerCase() || '');
  }, []);

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      const attributes = [
        {
          name: 'first_name',
          value: formValues?.firstName?.trim() || '',
        },
        {
          name: 'last_name',
          value: formValues?.lastName?.trim() || '',
        },
        {
          name: 'phone_number',
          value: formValues?.phoneNumber?.trim() || '',
        },
        {
          name: 'team',
          value: formValues?.team?.trim() || '',
        },
      ];

      try {
        const isCreateCognito = !isEdit && formValues.willAgentUseApp === 'YES';
        const isCreateNonCognito = !isEdit && formValues.willAgentUseApp === 'NO';

        if (isCreateCognito) {
          await createAgentCognito({
            variables: {
              subsidiaryId: currentSubsidiary?.id || '',
              email: formValues?.email?.trim(),
              name: formValues?.name?.trim(),
              attributes,
            },
          });
        } else if (isCreateNonCognito) {
          await createAgentNonCognito({
            variables: {
              subsidiaryId: currentSubsidiary?.id || '',
              name: formValues?.name?.trim(),
              attributes,
            },
          });
        } else {
          await setAgentAttributes({
            variables: {
              agentId: initialValues?.id || '',
              attributes,
            },
          });
        }
        if (!isCreateCognito) {
          onClose(); // We show completion message
        }
        notification.success({
          message: i18n.t(`agentForm.${actionType}SuccessTitle`),
          description: i18n.t(`agentForm.${actionType}SuccessDescription`),
        });
        await onSuccess();
      } catch (error) {
        notification.error({
          message: i18n.t(`agentForm.${actionType}FailTitle`),
          description: (error as any)?.message,
        });
      }
    },
    [
      isEdit,
      actionType,
      createAgentCognito,
      createAgentNonCognito,
      currentSubsidiary?.id,
      initialValues?.id,
      onClose,
      onSuccess,
      setAgentAttributes,
    ],
  );

  if (wasAgentCreated) {
    return <AccountCreated email={form.getFieldValue('email')} onClose={onClose} />;
  }

  return (
    <Form<FormValues>
      data-id="agentForm"
      form={form}
      layout="vertical"
      validateTrigger="onBlur"
      initialValues={{
        willAgentUseApp: initialValues?.willAgentUseApp || 'YES',
        ...initialValues,
      }}
      autoComplete="off"
      onFinish={handleSubmit}
    >
      <Form.Item
        name="willAgentUseApp"
        label={
          <Tooltip
            placement="right"
            title={
              <WillAgentUseAppTooltipDiv
                dangerouslySetInnerHTML={{ __html: i18n.t('agentForm.willAgentUseAppTooltipHtml') }}
              />
            }
          >
            <span style={{ cursor: 'help' }}>
              {i18n.t('agentForm.willAgentUseAppTitle')} <QuestionCircleOutlined />
            </span>
          </Tooltip>
        }
        style={{ marginBottom: '0.75rem', marginTop: '1rem' }}
      >
        <Radio.Group
          value={willAgentUseApp}
          onChange={(event) => {
            form.setFieldValue('willAgentUseApp', event.target.value);
          }}
        >
          <Space direction="horizontal">
            <Radio value="YES" disabled={isLoading || !!initialValues}>
              {i18n.t('common.yes')}
            </Radio>
            <Radio value="NO" disabled={isLoading || !!initialValues}>
              {i18n.t('common.no')}
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {willAgentUseApp === 'YES' && (
        <Form.Item
          name="email"
          label={i18n.t('agentForm.email')}
          rules={[{ validator: formEmailValidator }]}
        >
          <Input
            data-id="agentForm_email"
            type="email"
            placeholder={i18n.t<string>('agentForm.emailPlaceholder')}
            required
            disabled={isLoading || !!initialValues}
            defaultValue={initialValues?.email}
          />
        </Form.Item>
      )}
      {isCompanyAdminOrSuperAdmin && (
        <Form.Item name="subsidiary" label={i18n.t('agentForm.agentSubsidiary')}>
          <SettingsSelectSubsidiary disabled={isLoading || !!initialValues} />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label={i18n.t('common.agentCode')}
        rules={[{ validator: formAgentCodeValidator }]}
      >
        <Input
          data-id="agentForm_agentCode"
          type="text"
          placeholder={i18n.t<string>('agentForm.agentCodePlaceholder')}
          required
          disabled={isLoading || !!initialValues?.name}
        />
      </Form.Item>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gridGap: '12px',
        }}
      >
        <Form.Item
          name="firstName"
          label={i18n.t('agentForm.firstName')}
          rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
        >
          <Input
            data-id="agentForm_firstName"
            type="text"
            placeholder={i18n.t<string>('agentForm.firstNamePlaceholder')}
            disabled={isLoading}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={i18n.t('agentForm.lastName')}
          rules={[{ required: false, message: '' }, { validator: formNameValidator }]}
        >
          <Input
            data-id="agentForm_lastName"
            type="text"
            placeholder={i18n.t<string>('agentForm.lastNamePlaceholder')}
            disabled={isLoading}
          />
        </Form.Item>
      </div>
      <Form.Item name="team" label={i18n.t('agentForm.team')}>
        <AutoComplete
          options={teamOptions}
          onChange={handleTeamInputChange}
          placeholder={i18n.t<string>('agentForm.teamPlaceholder')}
          disabled={isLoading}
          showAction={['focus']}
          allowClear
          onBlur={() => {
            setAutoCompleteText('');
          }}
        >
          <Input data-id="agentForm_team" />
        </AutoComplete>
      </Form.Item>
      <Form.Item name="phoneNumber" label={i18n.t('agentForm.phoneNumber')}>
        <PhoneNumberInput
          data-id="agentForm_phoneNumber"
          placeholder={i18n.t<string>('agentForm.phoneNumberPlaceholder')}
          value={phoneNumber}
          onChange={(value) => {
            form.setFieldValue('phoneNumber', value);
          }}
          disabled={isLoading}
        />
      </Form.Item>
      <BottomFlexDiv>
        <Button data-id="agentForm_cancel" size="middle" disabled={isLoading} onClick={onClose}>
          {i18n.t('common.cancel')}
        </Button>
        <Button
          data-id="agentForm_cancel"
          size="middle"
          type="primary"
          htmlType="submit"
          loading={isLoading}
          icon={<SaveOutlined />}
        >
          {i18n.t('common.save')}
        </Button>
      </BottomFlexDiv>
      {isEdit && (
        <BottomDiv>
          <b>{i18n.t('agentDebugModal.agentId')}:</b> {agent?.id}
        </BottomDiv>
      )}
    </Form>
  );
});

AgentAddEditForm.displayName = 'AgentAddEditForm';

export default AgentAddEditForm;
