'use client';

import { Layout } from 'antd';
import { memo, type ReactNode } from 'react';
import styled from 'styled-components';

import AlarmsBottomBanner from '~/components/AlarmsBottomBanner';
import Navbar from '~/components/Navbar';
import AgentConnectionAndDeviceErrorListener from '~/components/notifications/AgentConnectionAndDeviceErrorListener';
import RefreshTokenExpirationListener from '~/components/notifications/RefreshTokenExpirationListener';
import theme from '~/theme';

const StyledLayout = styled(Layout)`
  background: ${theme.colors.grey};
  position: relative;
  width: 100%;
  min-height: 100vh;
`;

const StyledLayoutContent = styled(Layout.Content)``;

interface Props {
  children: ReactNode;
}

const PrivateLayout = memo(({ children }: Props) => (
  <>
    <StyledLayout>
      <Navbar />
      <Layout.Content>
        <StyledLayoutContent>
          {children}
          <AlarmsBottomBanner />
        </StyledLayoutContent>
      </Layout.Content>
    </StyledLayout>
    <AgentConnectionAndDeviceErrorListener />
    <RefreshTokenExpirationListener />
  </>
));

PrivateLayout.displayName = 'PrivateLayout';

export default PrivateLayout;
