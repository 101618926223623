'use client';

import { Checkbox, Form } from 'antd';
import { memo, ReactNode, useEffect, useState } from 'react';

interface Props {
  children: ReactNode;
  label: string;
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  isGlobal: boolean;
}

const ConfigurationToggleFormItem = memo(
  ({ children, label, checked, onChange, isGlobal }: Props) => {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
      setIsChecked(checked);
    }, [checked]);

    useEffect(() => {
      if (isGlobal) {
        setIsChecked(true);
      }
    }, [isGlobal]);

    return (
      <Form.Item>
        <div style={{ marginBottom: '16px' }}>
          <Checkbox
            style={{ cursor: isGlobal ? 'default' : 'pointer' }}
            checked={isChecked}
            onChange={(event) => {
              if (!isGlobal) {
                onChange(event.target.checked);
              }
            }}
          >
            {label}
          </Checkbox>
        </div>
        <div
          style={{
            pointerEvents: isChecked ? 'auto' : 'none',
            opacity: isChecked ? 1 : 0.25,
          }}
        >
          {children}
        </div>
      </Form.Item>
    );
  },
);

ConfigurationToggleFormItem.displayName = 'ConfigurationToggleFormItem';

export default ConfigurationToggleFormItem;
